@font-face{
    font-family: InterRegular;
    display: swap;
    font-display: swap;
    src: url("../fonts/Inter-Regular.ttf");
}
@font-face{
    font-family: InterBold;
    display: swap;
    font-display: swap;
    src: url("../fonts/Inter-Bold.ttf");
}


/*
@font-face{
    font-family: PTRootUI_Regular;
    display: swap;
    font-display: swap;
    src: url("../fonts/PT_Root_UI_Regular.woff") format("woff"),
        url("../fonts/PT_Root_UI_Regular.woff2") format("woff2"),
        url("../fonts/PT_Root_UI_Regular.eot"),
        url("../fonts/PT_Root_UI_Regular.ttf") format("opentype");
}
*/

@font-face{
    font-family: RUBSN;
    display: swap;
    src: url("../fonts/RUBSN.otf");
}
















