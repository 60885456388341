@import "_variables-main.scss";

$enable-cssgrid: true;
$enable-grid-classes: true;

// $body-bg: $color;
// $body-color: var(--color-normal-black);

$grid-columns: 12;
$grid-gutter-width: 1.5rem;

$success: #1E8B3B;//var(--color-primary-600);
$default: #ffffff;//var(--color-white);

$theme-colors: (
  "success": $success,
  "default": $default,
  // "danger": $danger,
  // "info": $info,
  // "modal": $modal
);


// :root{ --bs-link-hover-color: $color_hover; }


/**** BTN ****/
$btn-padding-y:               10px;
$btn-padding-x:               var(--indent-1);
$btn-font-family:             $font_InterBold;
$btn-font-size:               16px;
$btn-line-height:             20px;
// $btn-white-space:             null; // Set to `nowrap` to prevent text wrapping

// $btn-padding-y-sm:            $input-btn-padding-y-sm;
// $btn-padding-x-sm:            $input-btn-padding-x-sm;
// $btn-font-size-sm:            $input-btn-font-size-sm;

// $btn-padding-y-lg:            $input-btn-padding-y-lg;
// $btn-padding-x-lg:            $input-btn-padding-x-lg;
// $btn-font-size-lg:            $input-btn-font-size-lg;

$btn-border-width:            1px;
$btn-border-color:            var(--color-primary-500);
$btn-hover-border-color:      var(--color-primary-700);

// $btn-font-weight:             $font-weight-normal;
// $btn-box-shadow:              inset 0 1px 0 rgba($white, .15), 0 1px 1px rgba($black, .075);
$btn-focus-width:             0;
// $btn-focus-box-shadow:        $input-btn-focus-box-shadow;
// $btn-disabled-opacity:        .65;
// $btn-active-box-shadow:       inset 0 3px 5px rgba($black, .125);

// $btn-link-color:              var(--#{$prefix}link-color);
// $btn-link-hover-color:        var(--#{$prefix}link-hover-color);
// $btn-link-disabled-color:     $gray-600;

// // Allows for customizing button radius independently from global border radius
$btn-border-radius:           var(--round-1);
// $btn-border-radius-sm:        $border-radius-sm;
// $btn-border-radius-lg:        $border-radius-lg;

$btn-transition:              color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;

$btn-hover-bg-shade-amount:       0;
$btn-hover-bg-tint-amount:        0;
$btn-hover-border-shade-amount:   0;
$btn-hover-border-tint-amount:    0;
$btn-active-bg-shade-amount:      0;
$btn-active-bg-tint-amount:       0;
$btn-active-border-shade-amount:  0;
$btn-active-border-tint-amount:   0;

.btn{
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 52px;
  overflow: hidden;
  max-width: 100%;
  box-shadow: $shadow_01;
  SPAN, DIV{ position: relative; transition: 0s; }
  &:hover{
    box-shadow: none;
  }
  // &::before{
  //   @include blockAbs();
  //   background: none;
  //   transition: 0.3s;
  //   opacity: 0;
  // }
}

.btn.btn-success{
  display: flex;
  color: var(--color-white);
  border: 1px solid var(--color-primary-500);
  background: var(--color-primary-600);
  &:hover{
    color: var(--color-white);
    border-color: var(--color-primary-700);
    background: var(--color-hover);
  }
}
.btn-check:checked + .btn.btn-success, :not(.btn-check) + .btn.btn-success:active, .btn.btn-success:first-child:active, .btn.btn-success.active, .btn.btn-success.show{
    color: var(--color-white);
    border-color: var(--color-primary-700);
    background: var(--color-hover);
}

.btn.btn-default{
  display: flex;
  border: 1px solid var(--color-light-500);
  background: var(--color-light-50);;
  &:hover{
    border-color: var(--color-light-700);
    background: var(--color-light-100);
  }
}
.btn-check:checked + .btn.btn-default, :not(.btn-check) + .btn.btn-default:active, .btn.btn-default:first-child:active, .btn.btn-default.active, .btn.btn-default.show{
  border-color: var(--color-light-700);
  background: var(--color-light-100);
}



/**** BREADCRUMBS ****/
// $breadcrumb-font-size:              var(--font-size-1);
// $breadcrumb-padding-y:              0;
// $breadcrumb-padding-x:              0;
// $breadcrumb-item-padding-x:         var(--indent-1);
// $breadcrumb-margin-bottom:          var(--indent-1);
// $breadcrumb-bg:                     null;
// $breadcrumb-divider-color:          var(--color-normal);
// $breadcrumb-color:                  var(--color-normal);
// $breadcrumb-active-color:           var(--color-normal);
// $breadcrumb-divider:                quote("/");
// $breadcrumb-divider-flipped:        $breadcrumb-divider;
// $breadcrumb-border-radius:          null;


/**** MODAL ****/
$modal-inner-padding:               0;

// $modal-footer-margin-between:       .5rem;

// $modal-dialog-margin:               .5rem;
// $modal-dialog-margin-y-sm-up:       1.75rem;

// $modal-title-line-height:           $line-height-base;

$modal-content-color:               var(--color);
$modal-content-bg:                  var(--color-white);
$modal-content-border-color:        none;
$modal-content-border-width:        0;
$modal-content-border-radius:       0;
$modal-content-inner-border-radius: 0;
$modal-content-box-shadow-xs:       none;
$modal-content-box-shadow-sm-up:    none;

$modal-backdrop-bg:                 var(--color-normal);
$modal-backdrop-opacity:            0.6;

// $modal-header-border-color:         var(--#{$prefix}border-color);
// $modal-header-border-width:         $modal-content-border-width;
// $modal-header-padding-y:            $modal-inner-padding;
$modal-header-padding-x:            0;
$modal-header-padding:              0; // Keep this for backwards compatibility

// $modal-footer-bg:                   null;
// $modal-footer-border-color:         $modal-header-border-color;
// $modal-footer-border-width:         $modal-header-border-width;

$modal-sm:                          300px;
$modal-md:                          400px;
$modal-lg:                          800px;
$modal-xl:                          1140px;

// $modal-fade-transform:              translate(0, -50px);
// $modal-show-transform:              none;
// $modal-transition:                  transform .3s ease-out;
// $modal-scale-transform:             scale(1.02);

