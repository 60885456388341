@import "_fonts.scss";

$font: InterRegular;
$font_InterRegular: InterRegular;
$font_InterBold: InterBold;

$font_RUBSN: RUBSN;

// dark
:root{
    --color-dark-50: #76777E;
    --color-dark-100: #606166; 
    --color-dark-200: #4F4F53; 
    --color-dark-300: #404143; 
    --color-dark-400: #323234; 
    --color-dark-500: #28292A; 
    --color-dark-600: #202021; 
    --color-dark-700: #191919; 
    --color-dark-800: #151515; 
    --color-dark-900: #0F0F0F;
}
// light
:root {
    --color-light-50: #FFFFFF;
    --color-light-100: #FAFAFC;
    --color-light-200: #F4F5F8;
    --color-light-300: #EEEFF3;
    --color-light-400: #E4E6EB;
    --color-light-500: #DBDCE2;
    --color-light-600: #CED0D7;
    --color-light-700: #C0C2C9;
    --color-light-800: #A9ABB3;
    --color-light-900: #8D8F96;
}
// primary
:root {
    --color-primary-50: #F7FCF6; 
    --color-primary-100: #E0F8DF; 
    --color-primary-200: #BDEEBF; 
    --color-primary-300: #93DD9A; 
    --color-primary-400: #58C067; 
    --color-primary-500: #2D9F45; 
    --color-primary-600: #1E8B3B; 
    --color-primary-700: #147634; 
    --color-primary-800: #0D5D2C; 
    --color-primary-900: #073F20;
}

:root{
    --indent-1: 16px;
    --indent-2: 22px;
    --indent-3: 24px;
    --indent-4: 56px;
    --indent-5: 50px;

    --round-1: 6px;
    --round-2: 12px;

    --font-size-0: 48px;
    --font-size-1: 32px;
    --font-size-2: 28px;
    --font-size-3: 24px;
    --font-size-4: 18px;
    --font-size-5: 16px;
    --font-size-t: 16px;
    --font-size-d: 34px;

    --line-height-0: 56px;
    --line-height-1: 40px;
    --line-height-2: 36px;
    --line-height-3: 32px;
    --line-height-4: 24px;
    --line-height-5: 20px;
    --line-height-t: 24px;
    --line-height-d: 40px;

    --color-body: var(--color-light-200);
    --color-normal: var(--color-dark-50);
    --color-normal-black: var(--color-dark-600);
    --color-white: #ffffff;
    --color-link: var(--color-primary-600);
    --color-active: var(--color-primary-600);
    --color-hover: var(--color-primary-700);
    --color-bg-img: var(--color-light-300);

    --select-focus: var(--color-dark-300);
}

$color: #303030;
$color_white: #ffffff;
$color_black: #000000;
$color_hover: var(--color-hover);
$color_error: #FF6464;

$shadow_01: 0px 12px 32px rgba(88, 192, 103, 0.24);

$grad_01: radial-gradient(50% 100% at 50% 0%, var(--color-light-50) 0%, var(--color-light-200) 100%);
// $grad_01: radial-gradient(50% 100% at 50% 0%, red 0%, blue 100%);
$grad_02: linear-gradient(272.65deg, #202021 37.57%, rgba(32, 32, 33, 0.4) 95.57%);
$grad_03: linear-gradient(114.6deg, #58C067 0%, #1E8B3B 100%);
$grad_04: radial-gradient(130.96% 100% at 50% 0%, #606166 0%, #0F0F0F 41.74%);
$grad_05: linear-gradient(0deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8));

$shadow_01: -8px -8px 24px rgba(147, 221, 154, 0.15), 8px 8px 24px rgba(224, 248, 223, 0.2);

$image_01: url("data:image/svg+xml,%3Csvg width='432' height='306' viewBox='0 0 432 306' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg filter='url(%23filter0_f_33_879)'%3E%3Cpath d='M216 90L341.574 216H90.4263L216 90Z' fill='%23BDEEBF'/%3E%3C/g%3E%3Cdefs%3E%3Cfilter id='filter0_f_33_879' x='0.42627' y='0' width='431.147' height='306' filterUnits='userSpaceOnUse' color-interpolation-filters='sRGB'%3E%3CfeFlood flood-opacity='0' result='BackgroundImageFix'/%3E%3CfeBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape'/%3E%3CfeGaussianBlur stdDeviation='45' result='effect1_foregroundBlur_33_879'/%3E%3C/filter%3E%3C/defs%3E%3C/svg%3E%0A");
$image_02: url("data:image/svg+xml,%3Csvg width='432' height='306' viewBox='0 0 432 306' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg filter='url(%23filter0_f_33_879)'%3E%3Cpath d='M216 90L341.574 216H90.4263L216 90Z' fill='%23BDEEBF'/%3E%3C/g%3E%3Cdefs%3E%3Cfilter id='filter0_f_33_879' x='0.42627' y='0' width='431.147' height='306' filterUnits='userSpaceOnUse' color-interpolation-filters='sRGB'%3E%3CfeFlood flood-opacity='0' result='BackgroundImageFix'/%3E%3CfeBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape'/%3E%3CfeGaussianBlur stdDeviation='45' result='effect1_foregroundBlur_33_879'/%3E%3C/filter%3E%3C/defs%3E%3C/svg%3E%0A");

@mixin blockAbs{
    content: '';
    position: absolute;
    top: 0; left: 0;
    width: 100%; height: 100%;
}
@mixin defaultImg{
    position: relative;
    display: block;
    width: 100%; height: 100%;
    object-fit: cover;
    object-position: 50%;
}

@mixin blockquoteText{
    font-family: $font;
    font-size: var(--font-size-4);
    line-height: var(--line-height-4);
    letter-spacing: -0.01em;
    color: var(--color-normal);
    font-feature-settings: 'pnum' on, 'lnum' on;
}

@mixin hoverImg{
    &::before{
        content: '';
        position: absolute;
        top: 0; left: 0;
        width: 100%; height: 100%;
        background-color: $color;
        background-image: url("/img/search.png");
        background-position: 50%;
        background-size: 50px 50px;
        background-repeat: no-repeat;
        opacity: 0;
        transition: 0.3s;
        cursor: pointer;
    }
    &:hover{ &::before{ opacity: 1; } }
}
